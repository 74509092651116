import React, { useState } from "react";
import Stories from "react-insta-stories";
import { Card } from "@mui/material";
import style from './WebStory.module.css';

const WebStory = () => {
  const [currentStory, setCurrentStory] = useState(0);

  const stories = [
    {
      url: "https://cf-img-a-in.tosshub.com/sites/visualstory/wp/2024/07/cropped-Apple-WWDC24-iOS-18-iPhone-15-Pro-3-up-240610_bigjpglarge.jpg?size=*:900",
      header: { heading: 'Story 1' },
      duration: 2000 // 2 seconds
    },
    {
      url: "https://cf-img-a-in.tosshub.com/sites/visualstory/wp/2024/07/cropped-Apple-WWDC24-iOS-18-iPhone-15-Pro-3-up-240610_bigjpglarge.jpg?size=*:900",
      header: { heading: 'Story 2' },
      duration: 2000
    }
  ];

  // This will trigger when the story changes
  const handleStoryChange = (index) => {
    setCurrentStory(index); // Update the current story index
  };

  return (
    <Card className={style.storyCard}>
      <div className={style.storyContainer}>
        <Stories
          stories={stories}
          currentIndex={currentStory}
          onStoryEnd={(s, st) => handleStoryChange(st)} // Detect story end to trigger zoom
          defaultInterval={2000}
          width={360}
          height={600}
        />
        <div className={style.overlayZoom}>
          <img
            src={stories[currentStory]?.url}
            className={style.zoomEffect}
            alt={`Story ${currentStory + 1}`}
          />
        </div>
      </div>
    </Card>
  );
};

export default WebStory;


