import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styles from "./Footer.module.css";
import axios from "axios";
import LoadingTemplate from "../Components/LoadingTemplate";

const Footer = ({
  imageUrl,
  customUrl,
  categoryName,
  imageUrlAlt = "Magazine Cover Image",
  imageUrlTitle = "Magazine Cover",
  subCategoryName,
  loading
}) => {
  const [email, setEmail] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [category, setcategory] = useState([]);
  // Function to fetch all posts
  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        "https://www.backend.thenewstale.com/public/category/getAll"
      );
      setcategory(response?.data?.categories);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  // Fetch posts when the component mounts
  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSubscribe = () => {
    setShowSnackbar(true);
    setEmail("");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsMediaOrganization",
            name: "The News Tale",
            url: window.location.href,
            logo: "https://thenewstale.com/logo.png",
            sameAs: [
              "https://www.facebook.com/thenewstale",
              "https://www.instagram.com/thenewstale",
              "https://twitter.com/thenewstale",
              "https://www.youtube.com/thenewstale",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              email: "contact@thenewstale.com",
              contactType: "Customer Service",
            },
          })}
        </script>
      </Helmet>

      <Box component="footer" className={styles.footerContainer}>
        <Grid container className={styles.footerGrid}>
          {imageUrl && (
            <Grid item xs={12} md={3} className={styles.coverSection}>
              {loading ? 
              (
                <LoadingTemplate
                imageWidth={320}
                imageHeight={100}
                titleHeight={30}
                subtitleHeight={15}
                titleWidth="60%"
                subtitleWidth="40%"
                authorText="Loading..."
              />
              )
              :
              (
                <a
                className={styles.coverImage}
                href={`/${createCustomUrl(categoryName)}/${createCustomUrl(
                  subCategoryName
                )}/${createCustomUrl(customUrl)}`}
              >
                <img
                  src={imageUrl}
                  alt={imageUrlAlt}
                  title={imageUrlTitle}
                  loading="lazy"
                  className={styles.coverImage}
                />
              </a>
              )
            }
       
            </Grid>
          )}

          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.heading}>
                  The News Tale
                </Typography>
                <Link href="/about-us" className={styles.link}>
                  About Us
                </Link>
                <Link href="/contact-us" className={styles.link}>
                  Contact Us
                </Link>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.heading}>
                  Legal
                </Typography>
                <Link href="/terms-of-use" className={styles.link}>
                  Terms of Use
                </Link>
                <Link href="/privacy-policy" className={styles.link}>
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.heading}>
                  Follow Us
                </Typography>
                <Link
                  href="#"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </Link>
                <Link
                  href="https://www.instagram.com/thenewstale0/"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </Link>
                <Link
                  href="https://x.com/TheNewsTale0"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </Link>
                <Link
                  href="https://www.youtube.com/channel/UCO3zVuKXQpUFHdy56lQ8FdA"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </Link>
                <Link
                  href="https://www.linkedin.com/in/the-news-tale-55a377327"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </Link>
                <Link
                  href="https://www.pinterest.com/thenewstale0/"
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pinterest
                </Link>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6" className={styles.heading}>
                  More
                </Typography>
                {category.slice(0, 4).map((cate) => (
                  <Link key={cate?._id} href={`/${cate?.categoryName}`} className={styles.link}>
                    {cate?.categoryName}
                  </Link>
                ))}
              </Grid>
            </Grid>

            <Grid container spacing={2} className={styles.newsletterSection}>
              <Grid item xs={12} md={8} className={styles.newsletter}>
                <Typography variant="h6" className={styles.heading}>
                  Newsletter Sign Up
                </Typography>
                <Box className={styles.newsletterForm}>
                  <TextField
                    required
                    placeholder="Enter Your Email"
                    variant="outlined"
                    type="email"
                    className={styles.textField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    className={styles.subscribeButton}
                    onClick={handleSubscribe}
                  >
                    Subscribe
                  </Button>
                </Box>
                <Typography variant="body2" className={styles.privacyText}>
                  By providing your information, you agree to our Terms of Use
                  and our Privacy Policy.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Thank you for subscribing to our newsletter!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Footer;
