import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import axios from "axios";
import styles from "./SectionGrid.module.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#f1f3f4",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  display: "flex",
  alignItems: "center",
  border: "1px solid #ddd",
}));

const createCustomUrl = (text) => {
  return encodeURIComponent(text?.replace(/\s+/g, "-"));
};

const SectionGrid = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [subCategories, setSubCategories] = useState({});

  // Fetch all categories and subcategories
  const fetchCategoriesAndSubCategories = async () => {
    try {
      const categoryResponse = await axios.get(
        "https://www.backend.thenewstale.com/public/category/getAll"
      );
      const fetchedCategories = categoryResponse?.data?.categories || [];

      // Fetch subcategories for each category
      const subCategoryPromises = fetchedCategories.map((category) =>
        axios.get(
          `https://www.backend.thenewstale.com/public/subCategory/getAll/${category._id}`
        )
      );
      const subCategoryResponses = await Promise.all(subCategoryPromises);

      const fetchedSubCategories = subCategoryResponses.reduce(
        (acc, response, index) => {
          acc[fetchedCategories[index]._id] = response?.data?.data || [];
          return acc;
        },
        {}
      );

      setCategories(fetchedCategories);
      setFilteredCategories(fetchedCategories);
      setSubCategories(fetchedSubCategories);
    } catch (error) {
      console.error("Error fetching categories and subcategories:", error);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    filterCategories(value);
  };

  // Filter categories based on search term
  const filterCategories = (term) => {
    const filtered = categories.filter((category) =>
      category.categoryName.toLowerCase().includes(term)
    );
    setFilteredCategories(filtered);
  };

  // Fetch categories and subcategories on component mount
  useEffect(() => {
    fetchCategoriesAndSubCategories();
  }, []);

  return (
    <div className={styles.sectionGrid}>
      <div className={styles.header}>
        <a href="/">
          <h1 className={styles.title}>The News Tale</h1>
        </a>
        <br />
        <Search>
          <InputBase
            placeholder="Search…"
            value={searchTerm}
            onChange={handleSearchChange}
            inputProps={{ "aria-label": "search" }}
            className={styles.searchInput}
          />
        </Search>
      </div>
      <Grid container spacing={4} className={styles.gridContainer}>
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category) => (
            <Grid item xs={12} sm={6} md={3} key={category._id}>
              <a href={`/${createCustomUrl(category?.categoryName)}`}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  {category?.categoryName}
                </Typography>
              </a>

              {/* Display subcategories directly */}
              {subCategories[category._id] &&
                subCategories[category._id].length > 0 && (
                  <ul className={styles.subCategoryList}>
                    {subCategories[category._id].map((subCategory) => (
                      <li key={subCategory._id}>
                        <a
                          href={`/${createCustomUrl(
                            category?.categoryName
                          )}/${createCustomUrl(subCategory?.subCategoryName)}`}
                          className={styles.subCategoryLink}
                        >
                          {subCategory?.subCategoryName}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
            </Grid>
          ))
        ) : (
          <Typography variant="h6" className={styles.noResults}>
            No categories found
          </Typography>
        )}
      </Grid>
    </div>
  );
};

export default SectionGrid;
