// src/components/FeaturedVideo.js
import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./FeaturedVideo.module.css";

const FeaturedVideo = ({
  title,
  imgSrc,
  description,
  customUrl,
  category,
  subCategoryName,
  imageUrlAlt,
  imageUrlTitle,
}) => {
  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

  return (
    <Box className={styles.featuredVideo}>
      <img
        src={imgSrc}
        alt={imageUrlAlt}
        title={imageUrlTitle}
        width={"auto"}
        height={"auto"}
        loading="lazy"
        className={styles.image}
      />
      <a
        href={`/${createCustomUrl(category)}/${createCustomUrl(
          subCategoryName
        )}/${createCustomUrl(customUrl)}`}
      >
        <Typography variant="h5" className={styles.videoTitle}>
          {title}
        </Typography>
      </a>
      <Typography variant="body2" className={styles.description}>
        {description}cx
      </Typography>
    </Box>
  );
};

export default FeaturedVideo;
