import React, { useEffect, useState, Suspense, lazy } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import style from "./CategorySectionPage.module.css";
import { IntlProvider } from "react-intl";
import messages from "./messages"; // Your message translations
import axios from "axios";
import { useRecoilState } from "recoil";
import { errorState, loadingState, postsState } from "../Recoil";

const NavBar = lazy(() => import("../Components/NavBar"));
const Breadcrumb = lazy(() => import("../Components/Breadcrumb"));
const CategorySection = lazy(() => import("./CategorySection"));
const SubLayout = lazy(() => import("../Sections/SubLayout"));
const Footer = lazy(() => import("../Sections/Footer"));
const LifestyleSection = lazy(() => import("../Sections/LifestyleSection"));
const Alsolike = lazy(() => import("../Sections/Alsolike"));


const CategorySectionPage = () => {
  const [posts] = useRecoilState(postsState);
  const [loading, setLoading] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [error, setError] = useRecoilState(errorState);

  useEffect(() => {
    const filteredPosts = posts?.filter((post) => post?.bigScreen) || [];
    setFilteredPosts(filteredPosts);
  }, []);

  return (
    <HelmetProvider>
      <div className={style.main}>
        <Helmet>
          <title>The News Tale - Category Section</title>
          <meta
            name="description"
            content="Explore categories and stay updated with the latest news on The News Tale."
          />
          <meta
            name="keywords"
            content="news, categories, latest news, The News Tale"
          />
        </Helmet>

        {/* Google Analytics (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
          }}
        ></script>
        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
          }}
        ></noscript>
        {error ? (
          <div>{error}</div>
        ) : (
          <>
            <Suspense>
              <NavBar />
            </Suspense>
            <Suspense>
              <Breadcrumb />
            </Suspense>
            <IntlProvider locale="en" messages={messages["en"]}>
              <Suspense>
                <CategorySection />
              </Suspense>
              <Suspense>
                <SubLayout />
              </Suspense>
              <Suspense>
                <LifestyleSection />
              </Suspense>
              <Suspense>
                <Alsolike />
              </Suspense>
              <Suspense>
                <Footer
                  imageUrl={filteredPosts[0]?.imageUrl?.url}
                  imageUrlAlt={filteredPosts[0]?.imageUrlAlt}
                  imageUrlTitle={filteredPosts[0]?.imageUrlTitle}
                  customUrl={filteredPosts[0]?.customUrl}
                  categoryName={filteredPosts[0]?.catId?.categoryName}
                  subCategoryName={filteredPosts[0]?.subCatId?.subCategoryName}
                />
              </Suspense>
            </IntlProvider>
          </>
        )}
      </div>
    </HelmetProvider>
  );
};

export default CategorySectionPage;
