import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./NavBar.module.css";
import axios from "axios";
import HamburgerMenu  from "./HamburgerMenu"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [scrolled, setScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchTerm,setSearchTerm] = useState("")

  const [category, setcategory] = useState([]);
  // Function to fetch all posts
  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        "https://www.backend.thenewstale.com/public/category/getAll"
      );
      setcategory(response?.data?.categories);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  // Fetch posts when the component mounts
  useEffect(() => {
    fetchCategory();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleDrawer = (isOpen) => () => {
    setDrawerOpen(isOpen);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

   const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <AppBar
      position="fixed"
      className={`${styles.navBar} ${scrolled ? styles.navBarScrolled : ""}`}
    >
      <Toolbar
        className={`${styles.toolbar} ${
          scrolled ? styles.toolbarScrolled : ""
        }`}
      >
        <IconButton
          edge="start"
          className={styles.menuButton}
          color="inherit"
          aria-label="open navigation menu"
          onClick={toggleDrawer(true)} // This will open the drawer
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          className={styles.title}
          onClick={() => (window.location.href = "/")}
        >
          The News Tale
        </Typography>
        <div className={styles.rightSection}>
          <IconButton
            color="inherit"
            aria-label="search"
            onClick={handleSearchOpen}
          >
            <SearchIcon />
          </IconButton>
          <Button
            color="inherit"
            className={styles.languageButton}
            aria-controls="language-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <LanguageIcon /> EN
          </Button>
          <Menu
            id="language-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleCloseMenu}>EN</MenuItem>
            <MenuItem onClick={handleCloseMenu}>ES</MenuItem>
            <MenuItem onClick={handleCloseMenu}>FR</MenuItem>
            <MenuItem onClick={handleCloseMenu}>DE</MenuItem>
            <MenuItem onClick={handleCloseMenu}>ZH</MenuItem>
          </Menu>
          <Button color="inherit" className={styles.subscribeButton} onClick={scrollToBottom}>
            SUBSCRIBE
          </Button>
        </div>
      </Toolbar>
      {!scrolled && (
        <Toolbar className={styles.secondaryToolbar}>
          {category?.map((cate) => (
            <a key={cate?._id} href={`/${createCustomUrl(cate?.categoryName)}`}>
              <Button color="inherit">{cate?.categoryName}</Button>
            </a>
          ))}
        </Toolbar>
      )}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)} // This will close the drawer
        className={styles.drawer}
      >
        <HamburgerMenu toggleDrawer={toggleDrawer} />
      </Drawer>

      <Dialog
        open={searchOpen}
        onClose={handleSearchClose}
        TransitionComponent={Transition}
        aria-labelledby="search-dialog-title"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            margin: 0,
            top: 0,
            position: "absolute",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            width: isMobile ? "100%" : "80%",
          },
        }}
      >
        <DialogTitle id="search-dialog-title" className={styles.dialogTitle}>
          <Typography
            variant="h6"
            className={styles.title}
            onClick={() => (window.location.href = "/")}
          >
            The News Tale
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleSearchClose}
            aria-label="close"
            className={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.searchBox}>
          <InputBase
            autoFocus
            placeholder="Search…"
            fullWidth
            inputProps={{ "aria-label": "search" }}
            onChange={(e)=>setSearchTerm(e.target.value)}
            className={styles.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <a href={`/search/${searchTerm}`}>
          <Button color="inherit" className={styles.searchButton}>
            Search
          </Button>
          </a>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default NavBar;
