import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Pagination,
  TextField,
  Button,
  Box,
} from "@mui/material";
import styles from "./Search.module.css";
import { useParams } from "react-router-dom";
import NavBar from "../Components/NavBar";
import Footer from "../Sections/Footer";
import SubLayout from "../Sections/SubLayout";
import LifestyleSection from "../Sections/LifestyleSection";
import Alsolike from "../Sections/Alsolike";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import LoadingTemplate from "../Components/LoadingTemplate";
import { FormattedDate, IntlProvider } from "react-intl";
import { useRecoilState } from "recoil";
import { loadingState, postsState } from "../Recoil";

const POSTS_PER_PAGE = 4; // Number of posts to show per page

const messages = {}; // Define your translations/messages

const Search = () => {
  const { searchTerm } = useParams(); // Get the search term from the URL parameters
  const [query, setQuery] = useState(searchTerm);
  const [posts, setPosts] = useRecoilState(postsState);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [loading, setLoading] = useRecoilState(loadingState);

  // Function to fetch posts from the server
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://www.backend.thenewstale.com/user/newsPost/getAll"
      );
      const allPosts = response?.data?.posts || [];
      setPosts(allPosts);

      // Filter posts based on the search term in the description
      const filtered = allPosts.filter((post) =>
        post?.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filtered);
      setResultsCount(filtered.length);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(); // Fetch posts when the component mounts
  }, [searchTerm]); // Re-run the effect when the search term changes

  const handleSearch = () => {
    window.location.href = `/search/${query}`;
  };

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);

  // Get the posts to display on the current page
  const currentPosts = filteredPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

  // Schema Markup as JSON-LD
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: `Search results for "${query}"`,
    url: `https://thenewstale.com/search/${query}`,
    description: `Find the latest news and articles related to "${query}" on The News Tale.`,
    publisher: {
      "@type": "Organization",
      name: "The News Tale",
      logo: {
        "@type": "ImageObject",
        url: "https://res.cloudinary.com/dch7mrf5x/image/upload/v1725432823/BusImage/pps7fisfn1b0ri5fhwhx.png",
      },
    },
    mainEntity: currentPosts?.map((item) => ({
      "@type": "BlogPosting",
      headline: item?.title,
      image: {
        "@type": "ImageObject",
        url: item?.imageUrl?.url,
      },
      author: {
        "@type": "Person",
        name: "The News Tale",
      },
      datePublished: item?.publishedAt,
      description: item?.description,
      url: `https://thenewstale.com/${createCustomUrl(
        item?.categoryName
      )}/${createCustomUrl(item?.customUrl)}`,
    })),
  };

  return (
    <IntlProvider locale="en" messages={messages}>
      <div className={styles.main}>
        <Helmet>
          <title>{`Search results for "${query}" | The News Tale`}</title>
          <meta
            name="description"
            content={`Find the latest news and articles related to "${query}" on The News Tale. Stay updated with trending topics and breaking news.`}
          />
          <meta
            name="keywords"
            content={`${query} news, ${query}, latest ${query} news, trending ${query}, ${query} USA news, ${query} updates, ${query} breaking news, ${query} headlines, ${query} articles, ${query} latest updates, ${query} top stories, ${query} current news, ${query} developments, ${query} coverage, ${query} news today, ${query} trending topics, ${query} analysis, ${query} exclusive news, ${query} news reports, ${query} insights, ${query} news highlights, ${query} in-depth news, ${query} latest headlines, ${query} news now, ${query} updates and news, ${query} recent news`}
          />
          <link
            rel="canonical"
            href={`https://thenewstale.com/search/${query}`}
          />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
          <meta name="author" content="The News Tale" />
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
        </Helmet>

        <NavBar />
        <Container className={styles.container}>
          {/* Search Section */}
          <div className={styles.searchSection}>
            <Typography variant="body2" color="textSecondary">
              Showing results{" "}
              {currentPosts.length
                ? (currentPage - 1) * POSTS_PER_PAGE + 1
                : 0}{" "}
              -{" "}
              {currentPage * POSTS_PER_PAGE > resultsCount
                ? resultsCount
                : currentPage * POSTS_PER_PAGE}{" "}
              of {resultsCount} for
            </Typography>
            <Typography variant="h1" className={styles.queryText}>
              {query}
            </Typography>
            <br />
            <div className={styles.searchBar}>
              <TextField
                fullWidth
                variant="outlined"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className={styles.searchInput}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                className={styles.searchButton}
              >
                SEARCH
              </Button>
            </div>
          </div>
          {loading && (
            <Box>
              {[1, 2].map((_, index) => (
                <LoadingTemplate
                  key={index}
                  imageWidth={1100}
                  imageHeight={200}
                  titleHeight={30}
                  subtitleHeight={15}
                  titleWidth="60%"
                  subtitleWidth="40%"
                  authorText="Loading..."
                />
              ))}
            </Box>
          )}
          <Grid container spacing={3}>
            {!loading &&
              currentPosts.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Paper elevation={3} className={styles.paper}>
                    <Grid container spacing={3} className={styles.card_box}>
                      <Grid item xs={12} sm={4} className={styles.img_box}>
                        <img
                          src={item.imageUrl?.url}
                          alt={item.title}
                          className={styles.image}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <a
                          href={`/${createCustomUrl(
                            item?.catId?.categoryName
                          )}/${createCustomUrl(
                            item?.subCatId?.subCategoryName
                          )}/${createCustomUrl(item?.customUrl)}`}
                        >
                          <Typography variant="h6" className={styles.title}>
                            {item?.title}
                          </Typography>
                        </a>

                        <Typography variant="body2" color="textSecondary">
                          <FormattedDate
                            value={item?.updatedAt}
                            year="numeric"
                            month="long"
                            day="2-digit"
                            hour="2-digit"
                            minute="2-digit"
                            timeZone="UTC"
                          />
                        </Typography>
                        <Typography
                          variant="body1"
                          className={styles.description}
                        >
                          {item?.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
          {resultsCount > POSTS_PER_PAGE && (
            <div className={styles.paginationContainer}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                size="large"
              />
            </div>
          )}
        </Container>
        <LifestyleSection />
        <Alsolike />
        <Footer />
      </div>
    </IntlProvider>
  );
};

export default Search;
