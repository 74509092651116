import React from "react";
import { Container, Typography, Box, Grid, Avatar } from "@mui/material";
import styles from "./AboutUs.module.css"; // Create and style this CSS module
import NavBar from "../Components/NavBar";
import Footer from "../Sections/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AboutUs = () => {
  return (
    <HelmetProvider>
      <Helmet>
        {/* Page Title and Meta Description */}
        <title>About Us - THE NEWS TALE</title>
        <meta
          name="description"
          content="Learn more about THE NEWS TALE, a platform dedicated to bringing you the latest news and stories. Discover our mission, values, and the team behind the headlines."
        />
        <link rel="canonical" href="https://thenewstale.com/about-us" />
        {/* Open Graph Tags for Social Media Preview */}
        <meta property="og:title" content="About Us - THE NEWS TALE" />
        <meta
          property="og:description"
          content="Explore THE NEWS TALE and learn about our journey, mission, and the people behind the news. Stay informed with our latest updates."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thenewstale.com/about-us" />
        <meta
          property="og:image"
          content="https://thenewstale.com/path-to-image.jpg"
        />{" "}
        {/* Replace with an actual image URL */}
        {/* Twitter Card Tags for Enhanced Preview on Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - THE NEWS TALE" />
        <meta
          name="twitter:description"
          content="Discover the story behind THE NEWS TALE, our mission, and the team. Stay informed with our latest news and articles."
        />
        <meta
          name="twitter:image"
          content="https://thenewstale.com/path-to-image.jpg"
        />{" "}
        {/* Replace with an actual image URL */}
        <meta name="twitter:site" content="@thenewstale" />{" "}
        {/* Replace with your Twitter handle */}
        {/* Schema Markup for Better Search Engine Understanding */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://thenewstale.com",
            name: "THE NEWS TALE",
            logo: "https://thenewstale.com/path-to-logo.jpg", // Replace with your logo URL
            sameAs: [
              "https://www.facebook.com/thenewstale",
              "https://www.twitter.com/thenewstale",
              "https://www.instagram.com/thenewstale",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-123-456-7890",
              contactType: "Customer Service",
              areaServed: "US",
              availableLanguage: "English",
            },
            description:
              "THE NEWS TALE is your source for the latest news and stories. Learn more about our mission and the people who bring you the news.",
          })}
        </script>
      </Helmet>
      
        {/* Google Analytics (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
          }}
        ></script>

      {/* Google Tag Manager */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>

      <NavBar />
      <Container className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" component="h1" className={styles.title}>
            About Us
          </Typography>
          <Typography variant="body1" className={styles.introduction}>
            Welcome to THE NEWS TALE, your trusted source for the latest news
            and updates. We are dedicated to providing you with the most
            reliable and unbiased information, keeping you informed about the
            world around you.
          </Typography>
        </div>

        <section className={styles.section}>
          <Typography
            variant="h2"
            component="h2"
            className={styles.sectionTitle}
          >
            Our Mission
          </Typography>
          <Typography variant="body1" className={styles.paragraph}>
            Our mission is to deliver accurate and timely news that empowers our
            readers to stay informed, engaged, and connected to the issues that
            matter most. We strive to be a platform that fosters thoughtful
            discussion and provides a space for diverse perspectives.
          </Typography>
        </section>

        <section className={styles.section}>
          <Typography
            variant="h2"
            component="h2"
            className={styles.sectionTitle}
          >
            Our Vision
          </Typography>
          <Typography variant="body1" className={styles.paragraph}>
            We envision a world where information is accessible to everyone, and
            media plays a vital role in promoting understanding, accountability,
            and positive change. THE NEWS TALE is committed to setting the
            standard for responsible journalism in the digital age.
          </Typography>
        </section>

        <section className={styles.section}>
          <Typography
            variant="h2"
            component="h2"
            className={styles.sectionTitle}
          >
            Our Values
          </Typography>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <strong>Integrity:</strong> We uphold the highest standards of
              ethics in everything we do.
            </li>
            <li className={styles.listItem}>
              <strong>Accuracy:</strong> We strive to provide fact-checked and
              accurate information.
            </li>
            <li className={styles.listItem}>
              <strong>Transparency:</strong> We are open and honest about our
              processes, sources, and corrections.
            </li>
            <li className={styles.listItem}>
              <strong>Inclusivity:</strong> We respect diverse voices and seek
              to represent all perspectives fairly.
            </li>
          </ul>
        </section>

        <section className={styles.section}>
          <Typography
            variant="h2"
            component="h2"
            className={styles.sectionTitle}
          >
            Meet Our Team
          </Typography>
          <Grid container spacing={3}>
            {/* Team Member 1 */}
            <Grid item xs={12} md={4}>
              <Box className={styles.teamMember}>
                <Avatar
                  src="/path/to/team-member1.jpg" // Replace with actual image path
                  alt="Team Member 1"
                  className={styles.avatar}
                />
                <Typography variant="h6" className={styles.memberName}>
                  John Doe
                </Typography>
                <Typography variant="body2" className={styles.memberRole}>
                  Editor-in-Chief
                </Typography>
                <Typography variant="body2" className={styles.memberBio}>
                  John leads our editorial team with over 15 years of experience
                  in journalism and a passion for storytelling.
                </Typography>
              </Box>
            </Grid>

            {/* Team Member 2 */}
            <Grid item xs={12} md={4}>
              <Box className={styles.teamMember}>
                <Avatar
                  src="/path/to/team-member2.jpg" // Replace with actual image path
                  alt="Team Member 2"
                  className={styles.avatar}
                />
                <Typography variant="h6" className={styles.memberName}>
                  Jane Smith
                </Typography>
                <Typography variant="body2" className={styles.memberRole}>
                  Senior Reporter
                </Typography>
                <Typography variant="body2" className={styles.memberBio}>
                  Jane covers breaking news and investigative reports, bringing
                  in-depth insights to our readers.
                </Typography>
              </Box>
            </Grid>

            {/* Team Member 3 */}
            <Grid item xs={12} md={4}>
              <Box className={styles.teamMember}>
                <Avatar
                  src="/path/to/team-member3.jpg" // Replace with actual image path
                  alt="Team Member 3"
                  className={styles.avatar}
                />
                <Typography variant="h6" className={styles.memberName}>
                  Michael Brown
                </Typography>
                <Typography variant="body2" className={styles.memberRole}>
                  Content Strategist
                </Typography>
                <Typography variant="body2" className={styles.memberBio}>
                  Michael develops our content strategy, ensuring we deliver
                  engaging and relevant stories.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </section>
      </Container>
      <Footer />
    </HelmetProvider>
  );
};

export default AboutUs;
