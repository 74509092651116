import React from "react";
import { Container, Typography, Box, List, ListItem } from "@mui/material";
import styles from "./PrivacyPolicy.module.css";
import NavBar from "../Components/NavBar";
import Footer from "../Sections/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Privacy Policy - THE NEWS TALE</title>
        <meta
          name="description"
          content="Privacy policy for THE NEWS TALE, explaining how we collect, use, disclose, and protect your information."
        />
       <link rel="canonical" href="https://thenewstale.com/privacy-policy" />
      </Helmet>

        {/* Google Analytics (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
          }}
        ></script>
           {/* Google Tag Manager */}
           <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
      <NavBar />
      <Container className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" component="h1">
            PRIVACY POLICY- THE NEWS TALE
          </Typography>
          <Typography variant="body1">
            At THE NEWS TALE (“we,” “us,” or “our”), your privacy is one of our
            top priorities. This Privacy Policy explains how we collect, use,
            disclose, and protect your information when you visit our website
            thenewstale.com. By accessing or using the Services, you agree to
            the terms of this Privacy Policy. If you do not agree with this
            policy, please refrain from using our Services.
          </Typography>
        </div>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          1. Our Commitment to Your Privacy
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We understand that your privacy is important. As part of our
          commitment to safeguarding your information, we have developed this
          Privacy Policy in compliance with applicable privacy laws and industry
          best practices. We strive to ensure that our Services are secure and
          transparent, providing you with full control over the information you
          share with us. This policy outlines how we manage your personal
          information and explains your rights in relation to it.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          2. CHANGES TO THIS POLICY
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may update this Privacy Policy from time to time to reflect changes
          in legal requirements, technological advancements, and our information
          management practices. Any changes to this policy posted on our website
          will be updated. You are responsible for reviewing this Privacy Policy
          periodically to stay informed about our data collection and processing
          practices. Your continued use of our Services after the publication of
          changes constitutes your acceptance of those changes.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          3. INFORMATION WE COLLECT
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          1. Personal Data
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          When you register for an account on our website, subscribe to our
          newsletter, or engage with our Services in any way, we collect
          personal information that you voluntarily provide to us. This may
          include:
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Contact Information: such as your name, email address, mailing
            address, and phone number.
          </li>
          <li className={styles.listItem}>
            Account Information: including your username, password, and
            preferences.
          </li>
          <li className={styles.listItem}>
            Demographic Information: such as your age, gender, occupation,
            interests, and location.
          </li>
          <li className={styles.listItem}>
            Content You Provide: such as comments, posts, or other contributions
            to discussion forums or interactive areas of the Services.
          </li>
        </ul>
        <Typography variant="body1" className={styles.paragraph}>
          We may also collect additional personal information that you provide
          when you participate in surveys, promotions, contests, or interact
          with us in other ways. This data helps us tailor our services and
          communications to better suit your needs.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          2. Information Collected Automatically
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We automatically collect certain types of information when you access
          or use the Services. This information may include:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            Log Data: Information such as your IP address, browser type,
            operating system, referral URLs, and access times are automatically
            collected when you interact with the Services.
          </li>
          <li className={styles.listItem}>
            Device Information: Information about the device you use to access
            our Services, including your device’s unique identifiers, mobile
            network information, and operating system.
          </li>
          <li className={styles.listItem}>
            Usage Data: Information about your interactions with the Services,
            including the pages you visit, the features you use, and the time
            you spend on our platform.
          </li>
        </ul>
        <Typography variant="body1" className={styles.paragraph}>
          This information is collected through the use of cookies, web beacons,
          and other tracking technologies. These tools help us to improve the
          functionality of our Services, understand user behavior, and provide
          you with personalized content and advertisements.
        </Typography>
        {/* Add more sections as needed */}

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          3. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          What Are Cookies?
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Cookies are small text files that are placed on your device when you
          visit a website. They are widely used to make websites work, or work
          more efficiently, as well as to provide information to the site
          owners.
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Session Cookies: These are temporary cookies that expire once you
            close your browser.
          </li>
          <li className={styles.listItem}>
            Persistent Cookies: These remain on your device for a set period of
            time or until you delete them.
          </li>
        </ul>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          How We Use Cookies
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We use cookies and similar tracking technologies to:
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Recognize you when you return to our site.
          </li>
          <li className={styles.listItem}>
            Personalize your experience by remembering your preferences.
          </li>
          <li className={styles.listItem}>
            Provide analytics and insights into how users interact with our
            Services.
          </li>
          <li className={styles.listItem}>
            Ensure the security of our Services.
          </li>
        </ul>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          Third-Party Cookies
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Some content or applications on the Services are served by third
          parties, including advertisers, analytics providers, and content
          delivery networks. These third parties may use cookies alone or in
          conjunction with web beacons or other tracking technologies to collect
          information about you when you use our Services. They may associate
          the information they collect with your personal information or they
          may collect information about your online activities over time and
          across different websites and other online services.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          How to Manage Cookies
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You can control the use of cookies by adjusting your browser settings.
          Most web browsers allow you to manage cookie preferences, including
          disabling cookies. However, please be aware that disabling cookies may
          affect the functionality of our Services and your ability to access
          certain features.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          4. Financial Data
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may collect and store certain financial information when you make
          purchases through our Services, including credit card numbers, billing
          addresses, and payment transaction details. However, we do not retain
          full payment information; instead, our third-party payment processors
          (e.g., PayPal, Stripe) handle most transactions.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          5. Information From Social Networks
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          When you link your social media accounts (e.g., Facebook, Twitter,
          LinkedIn) to our Services, we collect information from those accounts,
          such as your name, email address, profile picture, and other public
          data. The amount of information we receive depends on your privacy
          settings for those social networks and your choices when you authorize
          the link.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          HOW WE USE YOUR INFORMATION
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We use the information we collect for a variety of purposes,
          including:
        </Typography>

        <ul className={styles.list}>
          <li className={styles.listItem}>
            Providing the Services: To fulfill your requests, provide access to
            our Services, and enhance your experience on our platform.
          </li>
          <li className={styles.listItem}>
            Communications: To send you updates, newsletters, and other
            promotional materials that you have consented to receive. You may
            opt out of these communications at any time.
          </li>
          <li className={styles.listItem}>
            Personalization: To deliver personalized content, recommendations,
            and advertisements based on your interests and browsing history.
          </li>
          <li className={styles.listItem}>
            Analytics: To analyze trends, usage patterns, and overall
            performance of our Services. We use these insights to make
            data-driven decisions and improve our platform.
          </li>
          <li className={styles.listItem}>
            Security and Fraud Prevention: To protect our Services and users
            from potential threats, unauthorized access, fraud, and illegal
            activity.
          </li>
          <li className={styles.listItem}>
            Compliance: To comply with our legal obligations, resolve disputes,
            and enforce our terms and policies.
          </li>
        </ul>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          DISCLOSURE OF YOUR INFORMATION
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may share your information with third parties in the following
          situations:
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          1. Service Providers
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We engage third-party companies and individuals to provide services on
          our behalf, such as hosting, data analysis, payment processing,
          customer support, and marketing. These service providers may have
          access to your personal information to perform their services but are
          contractually obligated to protect your information and only use it as
          necessary to provide the services.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          2. Legal Compliance
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may disclose your information if required to do so by law or in
          response to a valid legal request (e.g., subpoenas, court orders). We
          may also disclose information when we believe it is necessary to
          protect the safety, rights, or property of THE NEWS TALE, our users,
          or others.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          3. Business Transfers
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          If we undergo a business transaction, such as a merger, acquisition,
          or sale of assets, your information may be transferred as part of that
          transaction. In such cases, we will provide notice before your
          personal information is transferred and becomes subject to a different
          privacy policy.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          4. With Your Consent
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may share your information with third parties for purposes not
          described in this policy if we have your consent to do so. For
          example, you may choose to share information with a social media
          platform or third-party application via an integration feature we
          offer.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          DATA SECURITY
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          How We Protect Your Data
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We take data security seriously and have implemented reasonable
          administrative, technical, and physical measures to protect your
          personal information from unauthorized access, disclosure, alteration,
          and destruction. These measures include encryption, firewalls, secure
          access protocols, and regular security audits.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          No Absolute Security
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          While we make every effort to protect your information, no system is
          completely secure. We cannot guarantee the absolute security of your
          personal information. In the event of a data breach, we will promptly
          notify affected users and take necessary steps to mitigate the impact.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          Data Retention
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, comply with
          legal obligations, resolve disputes, and enforce our agreements. When
          we no longer need your personal information, we will securely delete
          or anonymize it.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          YOUR RIGHTS
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          1. Access, Correction, and Deletion
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You have the right to access the personal information we hold about
          you. You can also request corrections to inaccurate or incomplete
          data, or ask us to delete your information, subject to certain
          exceptions under the law.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          2. Data Portability
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          In some jurisdictions, you have the right to request a copy of the
          personal information you have provided to us in a structured, commonly
          used, and machine-readable format.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          3. Opt-Out of Marketing Communications
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You have the right to opt out of receiving marketing communications
          from us at any time by following the unsubscribe instructions provided
          in our emails or by contacting us directly.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          4. Right to Object
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You have the right to object to the processing of your personal data
          in instances where we are relying on legitimate interests as the
          lawful basis for our processing, and there is something about your
          particular situation that makes you want to object to processing on
          this ground. You also have the right to object to any processing we
          may do for direct marketing purposes. Once you make such a request, we
          will stop processing your personal data unless we can demonstrate
          compelling legitimate grounds for the processing which override your
          rights and interests, or where we need to process the data to
          establish, exercise, or defend legal claims.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          5. Restriction of Processing
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          In certain circumstances, you have the right to request that we
          restrict the processing of your personal data. For example, you can
          request that we restrict the processing of your data if:
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            You contest the accuracy of the data (the restriction would apply
            while we verify the accuracy of your data).
          </li>
          <li className={styles.listItem}>
            The processing is unlawful, but you prefer restriction over
            deletion.
          </li>
          <li className={styles.listItem}>
            We no longer need the data for the purposes of the processing, but
            you require the data for the establishment, exercise, or defense of
            legal claims.
          </li>
          <li className={styles.listItem}>
            You have objected to the processing (pending verification of whether
            our legitimate grounds override your rights).
          </li>
        </ul>
        <Typography variant="body1" className={styles.paragraph}>
          When processing is restricted, we will retain sufficient information
          about you to ensure that the restriction is respected in the future.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          6. Withdrawal of Consent
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Where we rely on your consent as the lawful basis for our processing
          of your personal data, you have the right to withdraw your consent at
          any time. However, please note that the withdrawal of your consent
          will not affect the lawfulness of any processing we carried out based
          on your consent before its withdrawal.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          7. Automated Decision-Making
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We generally do not use automated decision-making, including
          profiling, in a way that has legal or significant effects on you.
          However, if we do so, you have the right to request human
          intervention, express your point of view, and contest the decision.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          EXERCISING YOUR RIGHTS
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          To exercise any of your rights, please contact us using the
          information provided in the “Contact Us” section of this Privacy
          Policy. We may ask you to verify your identity before processing your
          request. We will respond to your request within the timeframe required
          by applicable laws. In some cases, we may refuse to comply with your
          request if it is manifestly unfounded or excessive, or if we are
          required to retain your personal data to comply with legal
          obligations.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          CHILDREN’S PRIVACY
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Our Services are not intended for use by children under the age of 13
          (or the applicable age of consent in your jurisdiction). We do not
          knowingly collect personal information from children under 13. If you
          are under 13, please do not provide any personal information through
          the Services. If we learn that we have collected personal information
          from a child under the age of 13 without verifiable parental consent,
          we will delete that information as quickly as possible. If you believe
          we might have any information from or about a child under 13, please
          contact us.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          INTERNATIONAL TRANSFERS
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Your personal information may be transferred to, and processed in,
          countries other than the country in which you are a resident. These
          countries may have data protection laws that are different from the
          laws of your country. By using our Services, you acknowledge and
          consent to the transfer of your personal data to other jurisdictions,
          including the United States, where we and our service providers
          operate. We ensure that appropriate safeguards are in place to protect
          your personal data during these transfers, in accordance with
          applicable data protection laws.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          DATA RETENTION
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We retain your personal data only for as long as necessary to fulfill
          the purposes for which it was collected, to provide you with the
          Services, or to comply with applicable legal, tax, or regulatory
          obligations. When determining the appropriate retention period for
          your personal information, we take into consideration:
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            The nature and sensitivity of the data.
          </li>
          <li className={styles.listItem}>
            The potential risk of harm from unauthorized use or disclosure.
          </li>
          <li className={styles.listItem}>
            The purposes for which we process your personal data and whether we
            can achieve those purposes through other means.
          </li>
          <li className={styles.listItem}>
            Applicable legal, regulatory, tax, or other requirements.
          </li>
        </ul>
        <Typography variant="body1" className={styles.paragraph}>
          Once we no longer need your personal data, we will securely delete or
          anonymize it. However, we may retain a limited amount of information
          after your account is closed for legitimate business purposes, such as
          for audit, legal, or fraud prevention purposes.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          THIRD-PARTY LINKS
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Our Services may contain links to third-party websites, apps, or
          services that are not affiliated with us. These third-party sites have
          their own privacy policies, and we encourage you to review them before
          providing them with any personal data. We are not responsible for the
          privacy practices or the content of these third-party sites. Your
          interactions with these sites are governed solely by the third
          parties' privacy policies and terms of service.:
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          THIRD-PARTY SERVICE PROVIDERS
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may employ third-party companies and individuals to facilitate our
          Services, to provide the Services on our behalf, to perform
          Services-related activities, or to assist us in analyzing how our
          Services are used. These third-party service providers have access to
          your personal information only to perform these tasks on our behalf
          and are contractually obligated not to disclose or use it for any
          other purpose.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          CHANGES TO THIS PRIVACY POLICY
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. When we make significant
          changes, we will notify you by email (if you have provided your email
          address) or by posting a prominent notice on our website. We encourage
          you to review this Privacy Policy periodically to stay informed about
          how we are protecting your information. If you continue to use our
          Services after the revised policy has been posted, you will be deemed
          to have accepted the changes. If you do not agree with the revised
          policy, you should discontinue your use of the Services.
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          CONTACT US
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, or if you would like to exercise your rights regarding
          your personal information, please contact us at:
        </Typography>
        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          THE NEWS TALE
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Email: contact@thenewstale.com
        </Typography>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
