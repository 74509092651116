import React, { useEffect, useState, Suspense, lazy } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import axios from "axios";
import style from "./SubCategorySectionPage.module.css";
import messages from "./messages"; // Your message translations
import { errorState, loadingState, postsState } from "../Recoil";
import { useRecoilState } from "recoil";

// Lazy loading components
const NavBar = lazy(() => import("../Components/NavBar"));
const Breadcrumb = lazy(() => import("../Components/Breadcrumb"));
const SubLayout = lazy(() => import("../Sections/SubLayout"));
const Footer = lazy(() => import("../Sections/Footer"));
const LifestyleSection = lazy(() => import("../Sections/LifestyleSection"));
const Alsolike = lazy(() => import("../Sections/Alsolike"));
const SubCategorySection = lazy(() => import("./SubCategorySection"));

const SubCategorySectionPage = () => {
  const [posts, setPosts] = useRecoilState(postsState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [error, setError] = useRecoilState(errorState);


  // Effect to filter posts
  useEffect(() => {
    // Filter posts that are marked as 'mostPopular'
    const mostPopularPosts = posts?.filter((post) =>post?.bigScreen) || [];
    // Reverse the array and set filtered posts
    setFilteredPosts(mostPopularPosts.reverse());
  }, [posts]);


  return (
    <HelmetProvider>
      <div className={style.main}>
        <Helmet>
          <title>The News Tale - Home</title>
          <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
          <meta
            name="description"
            content="Stay updated with the latest The News Tale."
          />
        </Helmet>

        {/* Google Analytics (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
          }}
        ></script>
             {/* Google Tag Manager */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
        <Suspense fallback={<div>Loading...</div>}>
          <NavBar />
          <Breadcrumb />
          <IntlProvider locale="en" messages={messages["en"]}>
            <SubCategorySection />
            <SubLayout />
            <LifestyleSection />
            <Alsolike />
            <Footer
              imageUrl={filteredPosts[0]?.imageUrl?.url}
              imageUrlAlt={filteredPosts[0]?.imageUrlAlt}
              imageUrlTitle={filteredPosts[0]?.imageUrlTitle}
              customUrl={filteredPosts[0]?.customUrl}
              categoryName={filteredPosts[0]?.catId?.categoryName}
              subCategoryName={filteredPosts[0]?.subCatId?.subCategoryName}
            />
          </IntlProvider>
        </Suspense>
      </div>
    </HelmetProvider>
  );
};

export default SubCategorySectionPage;
