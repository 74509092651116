import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./LifestyleSection.module.css";
import { useRecoilState } from "recoil";
import { errorState, loadingState, postsState } from "../Recoil";
import LoadingTemplate from "../Components/LoadingTemplate";

const LifestyleSection = () => {
  const [posts] = useRecoilState(postsState); // Get posts from Recoil state
  const [loading, setLoading] = useRecoilState(loadingState);
  const [error] = useRecoilState(errorState); // Get error state from Recoil
  const [lifestylePost, setLifestylePost] = useState([]);
  const [podcastPost, setPodcastPost] = useState([]);

  useEffect(() => {
    // Filter and reverse the posts for lifestyle and podcasts
    const filteredLifestylePosts = posts?.filter((post) => post?.lifestyle).reverse();
    const filteredPodcastPosts = posts?.filter((post) => post?.podcasts).reverse();

    setLifestylePost(filteredLifestylePosts);
    setPodcastPost(filteredPodcastPosts);
  }, [posts]);

  // Split lifestyle posts into main article and small articles
  const mainArticle = lifestylePost[0];
  const smallArticles = lifestylePost.slice(1, 5);

  // Create custom URL for articles
  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

  if (loading) {
    return (
      <Box className={styles.container}>
        <LoadingTemplate
          imageWidth={500}
          imageHeight={200}
          titleHeight={20}
          subtitleHeight={10}
          titleWidth="80%"
          subtitleWidth="60%"
          authorText="Loading..."
        />
        {error && (
          <Typography variant="h6" color="error" className={styles.error}>
            {error}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      {/* Lifestyle Section Header */}
      <Grid container className={styles.header}>
        <Typography variant="h4" className={styles.title}>
          LIFESTYLE
        </Typography>
      </Grid>

      {/* Main Lifestyle Article */}
      <Grid container className={styles.mainArticle}>
        {mainArticle && (
          <Grid item xs={12} md={7}>
            <img
              src={mainArticle?.imageUrl?.url}
              alt={mainArticle?.imageUrlAlt}
              title={mainArticle?.imageUrlTitle}
              className={styles.mainImage}
              loading="lazy"
            />
            <Typography variant="caption" className={styles.category}>
              {mainArticle?.category}
            </Typography>
            <a
              href={`/${createCustomUrl(mainArticle?.catId?.categoryName)}/${createCustomUrl(
                mainArticle?.subCatId?.subCategoryName
              )}/${createCustomUrl(mainArticle?.customUrl)}`}
            >
              <Typography variant="h5" className={styles.mainTitle}>
                {mainArticle?.title}
              </Typography>
            </a>
            <Typography variant="body2" className={styles.author}>
              {mainArticle?.authorData?.author}
            </Typography>
          </Grid>
        )}

        {/* Small Lifestyle Articles */}
        <Grid item xs={12} md={5} className={styles.smallArticles}>
          <Grid container spacing={2}>
            {smallArticles.map((article, index) => (
              <Grid item xs={6} key={index}>
                <a
                  href={`/${createCustomUrl(article?.catId?.categoryName)}/${createCustomUrl(
                    article?.subCatId?.subCategoryName
                  )}/${createCustomUrl(article?.customUrl)}`}
                >
                  <img
                    src={article?.imageUrl?.url}
                    alt={article?.imageUrlAlt}
                    title={article?.imageUrlTitle}
                    className={styles.smallImage}
                    loading="lazy"
                  />
                  <Typography variant="caption" className={styles.smallCategory}>
                    {article?.category}
                  </Typography>
                  <Typography variant="body2" className={styles.smallTitle}>
                    {article?.title}
                  </Typography>
                  <Typography variant="body2" className={styles.smallAuthor}>
                    {article?.authorData?.author}
                  </Typography>
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Podcast Section */}
      <Grid container className={styles.podcastSection}>
        <Grid item xs={12} className={styles.podcastHeader}>
          <Typography variant="h4" className={styles.podcastTitle}>
            Podcasts
          </Typography>
        </Grid>

        <Grid container spacing={2} className={styles.podcastContent}>
          {podcastPost.slice(0, 4).map((podcast, index) => (
            <Grid item xs={12} md={6} key={index}>
              <a
                href={`/${createCustomUrl(podcast?.catId?.categoryName)}/${createCustomUrl(
                  podcast?.subCatId?.subCategoryName
                )}/${createCustomUrl(podcast?.customUrl)}`}
              >
                <img
                  src={podcast?.imageUrl?.url}
                  alt={podcast?.imageUrlAlt}
                  title={podcast?.imageUrlTitle}
                  className={styles.podcastImage}
                  loading="lazy"
                />
                <Typography variant="body2" className={styles.podcastText}>
                  {podcast?.title}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LifestyleSection;
