import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useRecoilState } from "recoil";
import { errorState, loadingState, postsState } from "../Recoil";
import styles from "./Alsolike.module.css";
import LoadingTemplate from "../Components/LoadingTemplate";

const Alsolike = () => {
  const [posts] = useRecoilState(postsState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [Allposts, setAllPosts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // Filter and set the posts
    const filteredPosts = posts
      ?.filter((post) => post?.youMayAlsoLike)
      ?.reverse(); // Reverse the youMayAlsoLike array

    setAllPosts(filteredPosts);
  }, [posts]);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 4) % Allposts.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? Allposts.length - 4 : prev - 4));
  };

  function convertToBlogDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };

  return (
    <Box className={styles.container}>
      <Grid container>
        {/* Left Section - Most Popular */}
        <Grid item xs={12} md={6} className={styles.leftSection}>
          <Typography variant="h3" className={styles.heading}>
            MOST POPULAR
          </Typography>
          <Box className={styles.listContainer}>
            {posts
              ?.filter((post) => post?.mostPopular)
              ?.reverse()
              ?.slice(0, 3)
              .map((item, index) => (
                <Box key={index} className={styles.listItem}>
                  <a
                    href={`/${createCustomUrl(
                      item?.catId?.categoryName
                    )}/${createCustomUrl(
                      item?.subCatId?.subCategoryName
                    )}/${createCustomUrl(item?.customUrl)}`}
                  >
                    <Typography variant="h6" className={styles.source}>
                      {`${index + 1} . ${item.title}`}
                    </Typography>
                  </a>
                </Box>
              ))}
          </Box>
        </Grid>

        {/* Right Section - You May Also Like */}
        <Grid item xs={12} md={6} className={styles.rightSection}>
          <Typography variant="h3" className={styles.heading}>
            YOU MAY ALSO LIKE
          </Typography>
          <Box className={styles.carousel}>
            {loading ? (
              <LoadingTemplate
                imageWidth={150}
                imageHeight={100}
                titleHeight={30}
                subtitleHeight={15}
                titleWidth="60%"
                subtitleWidth="40%"
                authorText="Loading..."
              />
            ) : (
              <>
                <Grid container spacing={2}>
                  {Allposts
                    ?.slice(currentSlide, currentSlide + 4)
                    .map((item, index) => (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        key={index}
                        className={styles.carouselItem}
                      >
                        <a
                          href={`/${createCustomUrl(
                            item?.catId?.categoryName
                          )}/${createCustomUrl(
                            item?.subCatId?.subCategoryName
                          )}/${createCustomUrl(item?.customUrl)}`}
                        >
                          <img
                            src={item.imageUrl?.url}
                            alt={item?.imageUrlAlt}
                            title={item?.imageUrlTitle}
                            height={"auto"}
                            width={"auto"}
                            loading="lazy"
                            className={styles.carouselImage}
                          />
                          <Typography
                            variant="body2"
                            className={styles.carouselText}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={styles.carouselTime}
                          >
                            {convertToBlogDate(item?.updatedAt)}
                          </Typography>
                        </a>
                      </Grid>
                    ))}
                </Grid>
                <Box className={styles.navigation}>
                  <IconButton onClick={handlePrev} disabled={currentSlide === 0}>
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleNext}
                    disabled={currentSlide >= Allposts.length - 4}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Alsolike;
