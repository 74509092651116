// src/components/Sidebar.js
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import styles from "./Sidebar.module.css";
import LoadingTemplate from "../Components/LoadingTemplate";

const Sidebar = ({ position, videos }) => {
  const createCustomUrl = (text) => {
    return encodeURIComponent(text?.replace(/\s+/g, "-"));
  };
  return (
    <Box
      className={position === "left" ? styles.leftSidebar : styles.rightSidebar}
    >
      {videos.map((video, index) => (
        <Box
          key={index}
          className={styles.sidebarItem}
          onClick={() => (window.location.href = video.link)}
        >
          <a
            href={`/${createCustomUrl(
              video?.catId?.categoryName
            )}/${createCustomUrl(
              video?.subCatId?.subCategoryName
            )}/${createCustomUrl(video?.customUrl)}`}
          >
            {video.imageUrl?.url ? (
              <img
                src={video.imageUrl?.url}
                alt={video?.imageUrlAlt}
                title={video?.imageUrlTitle}
                height={"auto"}
                width={"auto"}
                loading="lazy"
                className={styles.videoThumbnail}
              />
            ) : (
              <LoadingTemplate
                imageWidth={200}
                imageHeight={100}
                titleHeight={30}
                subtitleHeight={15}
                titleWidth="70%"
                subtitleWidth="50%"
                authorText="Loading..."
              />
            )}
            <Typography variant="body2" className={styles.videoTitle}>
              {video.title}
            </Typography>
          </a>
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;
