import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Category from "./Pages/Category";
import AboutUs from "./Pages/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import CategorySectionPage from "./Pages/CategorySectionPage";
import Search from "./Pages/Search";
import { HelmetProvider } from "react-helmet-async";
import SubCategorySectionPage from "./Pages/SubCategorySectionPage";
import TermsOfUse from "./Pages/TermsOfUse";
import WebStory from "./Pages/WebStory";
import WebStoryPage from "./Pages/WebStoryPage";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/:categoryName/:subCategoryName/:customUrl"
              element={<Category />}
            />
            <Route
              path="/:categoryName/:subCategoryName"
              element={<SubCategorySectionPage />}
            />
            <Route path="/:categoryName" element={<CategorySectionPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/search/:searchTerm" element={<Search />} />
            <Route path="/web-story" element={<WebStoryPage />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </RecoilRoot>
  );
}

export default App;
