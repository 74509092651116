import React from "react";
import WebStory from "./WebStory";
import style from "./WebStoryPage.module.css";

function WebStoryPage() {
  return (
    <div className={style.main}>
      <WebStory />
    </div>
  );
}

export default WebStoryPage;
