import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import FeaturedVideo from "./FeaturedVideo";
import Sidebar from "./Sidebar";
import styles from "./SubLayout.module.css";
import LoadingTemplate from "../Components/LoadingTemplate";
import { useRecoilState } from "recoil";
import { errorState, loadingState, postsState } from "../Recoil";

const SubLayout = () => {
  const [posts] = useRecoilState(postsState); // Read posts from Recoil state
  const [loading, setLoading] = useRecoilState(loadingState);
  const [error] = useRecoilState(errorState); // Read error state from Recoil
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    if (posts) {
      // Filter and reverse the posts array
      const mostPopularPosts = posts
        ?.filter((post) => post?.featuredVideos)
        ?.reverse(); // Reverse the array here

      setFilteredPosts(mostPopularPosts);
    }
  }, [posts]); // Only re-run effect if posts change

  // Split videos into two groups for sidebars
  const leftVideos = filteredPosts?.slice(1, 4);
  const rightVideos = filteredPosts?.slice(4, 7);

  if (loading) {
    return (
      <Box className={styles.container}>
        <LoadingTemplate
          imageWidth={500}
          imageHeight={300}
          titleHeight={30}
          subtitleHeight={15}
          titleWidth="70%"
          subtitleWidth="50%"
          authorText="Loading..."
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.container}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.container}>
      <Typography variant="h4" className={styles.heading}>
        Featured Posts
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {/* Left Sidebar */}
        <Grid item xs={12} md={3}>
          <Sidebar position="left" videos={leftVideos} />
        </Grid>

        {/* Main Featured Video */}
        <Grid item xs={12} md={6}>
          <Box className={styles.featuredVideos}>
            {filteredPosts[0] ? (
              <FeaturedVideo
                title={filteredPosts[0]?.title}
                imgSrc={filteredPosts[0]?.imageUrl?.url}
                description={filteredPosts[0]?.description}
                customUrl={filteredPosts[0]?.customUrl}
                category={filteredPosts[0]?.catId?.categoryName}
                subCategoryName={filteredPosts[0]?.subCatId?.subCategoryName}
                imageUrlAlt={filteredPosts[0]?.imageUrlAlt}
                imageUrlTitle={filteredPosts[0]?.imageUrlTitle}
              />
            ) : (
              <Typography variant="h6">No featured post available.</Typography>
            )}
          </Box>
        </Grid>

        {/* Right Sidebar */}
        <Grid item xs={12} md={3}>
          <Sidebar position="right" videos={rightVideos} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubLayout;
