import React from "react";
import { Container, Typography, Box, List, ListItem } from "@mui/material";
import styles from "./TermsOfUse.module.css";
import NavBar from "../Components/NavBar";
import Footer from "../Sections/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TermsOfUse = () => {
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Terms Of Use - THE NEWS TALE</title>
        <meta
          name="description"
          content="Terms Of Use for THE NEWS TALE, explaining how we collect, use, disclose, and protect your information."
        />
        <link rel="canonical" href="https://thenewstale.com/terms-of-use" />
      </Helmet>

      {/* Google Analytics (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
        }}
      ></script>
      {/* Google Tag Manager */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
      <NavBar />
      <Container className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" component="h1">
            Terms of Use
          </Typography>
          <Typography variant="body1">
            Welcome to THE NEWS TALE. These Terms of Use govern your use of the
            websites, applications, and services provided by THE NEWS TALE.
          </Typography>
        </div>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          By accessing, browsing, or using the Site, you acknowledge that you
          have read, understood, and agree to be bound by these Terms.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          2. Privacy Policy
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          Please review our Privacy Policy to understand our practices regarding
          the collection, use, and disclosure of your personal information.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          3. Use of the Site
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          THE NEWS TALE grants you a limited, non-exclusive, non-transferable,
          and revocable license to access and use the Site.
        </Typography>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Engaging in any unauthorized access to or use of data, accounts,
            servers, or networks.
          </li>
          <li className={styles.listItem}>
            Transmitting or distributing any viruses, malware, or other harmful
            software.
          </li>
          <li className={styles.listItem}>
            Interfering with the proper working of the Site.
          </li>
          <li className={styles.listItem}>
            Using the Site to distribute, promote, or engage in spam, chain
            letters, or any other unsolicited messages.
          </li>
        </ul>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          4. Intellectual Property Rights
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          All content, features, and functionality available on the Site are the
          property of THE NEWS TALE or its licensors.
        </Typography>

        {/* Add more sections as needed */}

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          5. User Contributions
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          The Site may contain message boards, chat rooms, personal web pages or
          profiles, and other interactive features.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          6. Prohibited Uses of User Contributions
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You agree not to post any User Contributions that contain any material
          that is defamatory, obscene, abusive, or otherwise objectionable.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          7. Reliance on Information Posted
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          The information presented on or through the Site is made available
          solely for general information purposes.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          8. Copyright Infringement
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          If you believe that any User Contributions or other content available
          on or through the Site infringes your copyright, you may request
          removal.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          9. Links to Third-Party Sites
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          The Site may contain links to third-party websites or resources that
          are not owned or controlled by THE NEWS TALE.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          10. Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          THE NEWS TALE DOES NOT WARRANT THAT THE SITE WILL OPERATE ERROR-FREE
          OR THAT THE SITE, ITS SERVERS, OR CONTENT ARE FREE OF COMPUTER
          VIRUSES.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          11. Limitation of Liability
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          IN NO EVENT SHALL THE NEWS TALE, ITS AFFILIATES, OR THEIR LICENSORS,
          SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE
          FOR DAMAGES OF ANY KIND.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          12. Indemnification
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          You agree to indemnify, defend, and hold harmless THE NEWS TALE, its
          affiliates, and their respective officers, directors, employees,
          agents, and licensors.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          13. Termination
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          THE NEWS TALE reserves the right to terminate or suspend your access
          to the Site, in its sole discretion, without notice and for any
          reason.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          14. Governing Law and Dispute Resolution
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          These Terms and any dispute arising out of or related to these Terms
          or the Site shall be governed by and construed in accordance with the
          laws of the United States.
        </Typography>

        <Typography variant="h2" component="h2" className={styles.sectionTitle}>
          15. Miscellaneous
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          These Terms, together with our Privacy Policy and any other legal
          notices published by THE NEWS TALE on the Site, constitute the entire
          agreement between you and THE NEWS TALE.
        </Typography>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default TermsOfUse;
