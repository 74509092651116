import React, { useState } from "react";
import { Container, Typography, Box, TextField, Button, Grid, Snackbar, Alert } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import styles from "./ContactUs.module.css"; // Ensure this CSS module is styled properly
import NavBar from "../Components/NavBar";
import Footer from "../Sections/Footer";


const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic, e.g., sending data to the backend
    setFormStatus("Thank you for reaching out! We will get back to you soon.");
    setOpenSnackbar(true); // Open the snackbar on form submission
    // Reset form fields
    setFormData({ name: "", email: "", message: "" });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.main}>
      {/* SEO Configuration using Helmet */}
      <Helmet>
        <title>Contact Us - THE NEWS TALE</title>
        <meta
          name="description"
          content="Contact THE NEWS TALE with your questions, feedback, or tips. Fill out our contact form or reach us directly through our provided contact information."
        />
        <link rel="canonical" href="https://thenewstale.com/contact-us" />

        {/* Open Graph Meta Tags for Social Media Preview */}
        <meta property="og:title" content="Contact Us - THE NEWS TALE" />
        <meta
          property="og:description"
          content="Reach out to THE NEWS TALE with your questions, feedback, or tips. Use our contact form or connect directly with our team."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thenewstale.com/contact-us" />
        <meta property="og:image" content="https://thenewstale.com/path-to-image.jpg" /> {/* Replace with your image URL */}

        {/* Twitter Card Meta Tags for Enhanced Preview on Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - THE NEWS TALE" />
        <meta
          name="twitter:description"
          content="Get in touch with THE NEWS TALE team for any inquiries, feedback, or tips. We are here to help you stay informed."
        />
        <meta name="twitter:image" content="https://thenewstale.com/path-to-image.jpg" /> {/* Replace with your image URL */}
        <meta name="twitter:site" content="@thenewstale" /> {/* Replace with your Twitter handle */}

        {/* Schema Markup for Enhanced Search Engine Understanding */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "url": "https://thenewstale.com/contact-us",
            "description": "Contact THE NEWS TALE with your questions, feedback, or tips.",
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer Service",
              "areaServed": "US",
              "availableLanguage": "English",
            },
            "organization": {
              "@type": "Organization",
              "name": "THE NEWS TALE",
              "url": "https://thenewstale.com",
              "logo": "https://thenewstale.com/path-to-logo.jpg", // Replace with your logo URL
            },
          })}
        </script>
      </Helmet>

        {/* Google Analytics (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
          }}
        ></script>
     {/* Google Tag Manager */}
     <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
      <NavBar />
      <Container className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h1" component="h1" className={styles.title}>
            Contact Us
          </Typography>
          <Typography variant="body1" className={styles.introduction}>
            We value your feedback and inquiries. Please fill out the form below
            or reach out to us using the contact information provided.
          </Typography>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={styles.inputField}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={styles.inputField}
                required
              />
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                name="message"
                value={formData.message}
                onChange={handleChange}
                className={styles.inputField}
                multiline
                rows={4}
                required
              />
              <Button
                type="submit"
                variant="contained"
                className={styles.submitButton}
              >
                Submit
              </Button>
            </form>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={styles.contactInfo}>
              <Typography
                variant="h2"
                component="h2"
                className={styles.sectionTitle}
              >
                Contact Information
              </Typography>
              <Typography variant="body1" className={styles.paragraph}>
                If you prefer, you can contact us directly using the information
                below:
              </Typography>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <strong>Email:</strong> contact@thenewstale.com
                </li>
                {/* <li className={styles.listItem}>
                  <strong>Phone:</strong> (123) 456-7890
                </li> */}
                <li className={styles.listItem}>
                  <strong>Address:</strong> 123 News Tale Street, Suite 100,
                  City, State, USA
                </li>
                <li className={styles.listItem}>
                  <strong>Working Hours:</strong> Monday - Friday, 9:00 AM - 5:00 PM
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />

      {/* Snackbar for form submission feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          {formStatus}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;
