import React, { useEffect, useState, Suspense, lazy } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import style from "./Home.module.css";
import { IntlProvider } from "react-intl";
import messages from "./messages"; // Your message translations";
import axios from "axios";
import { useRecoilState } from "recoil";
import { errorState, loadingState, postsState } from "../Recoil";


// Lazy load components
const NavBar = lazy(() => import("../Components/NavBar"));
const Breadcrumb = lazy(() => import("../Components/Breadcrumb"));
const HomePageLayout = lazy(() => import("../Sections/HomePageLayout"));
const SubLayout = lazy(() => import("../Sections/SubLayout"));
const Footer = lazy(() => import("../Sections/Footer"));
const LifestyleSection = lazy(() => import("../Sections/LifestyleSection"));
const Alsolike = lazy(() => import("../Sections/Alsolike"));

const Home = () => {
  const [posts, setPosts] = useRecoilState(postsState);
  const [bigScreenPost,setBigScreenPost] = useState([])
  const [loading, setLoading] = useRecoilState(loadingState);
  const [error, setError] = useRecoilState(errorState);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        "https://www.backend.thenewstale.com/user/newsPost/getAll"
      );
      setPosts(response?.data?.posts);
      const filteredPosts =
        response?.data?.posts?.filter((post) => post?.bigScreen) || [];
      setBigScreenPost(filteredPosts)
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError("Unable to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const generateStructuredData = (post) => ({
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: "Breaking News Today",
    author: {
      "@type": "Person",
      name: "The News Tale",
      image:
        "https://res.cloudinary.com/dch7mrf5x/image/upload/v1725432823/BusImage/pps7fisfn1b0ri5fhwhx.png",
    },
    datePublished: "2024-08-30",
    dateModified: "2024-08-30",
    publisher: {
      "@type": "Organization",
      name: "The News Tale",
      logo: {
        "@type": "ImageObject",
        url: "https://res.cloudinary.com/dch7mrf5x/image/upload/v1725432823/BusImage/pps7fisfn1b0ri5fhwhx.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://thenewstale.com/",
    },
    articleSection: "news",
    description:
      "Stay informed with the latest breaking news, top headlines, and trending stories in the USA. Get real-time updates on politics, business, technology, and more from The News Tale.",
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Breaking News Today: Top US Headlines, Latest Updates & Trending
          Stories | The News Tale
        </title>
        <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <link rel="canonical" href="https://thenewstale.com/" />
        <meta
          name="description"
          content="Stay informed with the latest breaking news, top headlines, and trending stories in the USA. Get real-time updates on politics, business, technology, and more from The News Tale. Your trusted source for reliable news."
        />
        <meta
          name="keywords"
          content="Breaking News USA, Latest US News, Top Headlines, US Politics, Business News, Technology Updates, Trending Stories, Real-time News, US Events, National News, Today’s News USA, Local News USA, Current Affairs, News Updates"
        />
        <meta
          property="og:title"
          content="Breaking News Today: Top US Headlines, Latest Updates & Trending Stories | The News Tale"
        />
        <meta
          property="og:description"
          content="Stay informed with the latest breaking news, top headlines, and trending stories in the USA. Get real-time updates on politics, business, technology, and more from The News Tale. Your trusted source for reliable news."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dch7mrf5x/image/upload/v1725432823/BusImage/pps7fisfn1b0ri5fhwhx.png"
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://thenewstale.com/" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Breaking News Today: Top US Headlines, Latest Updates & Trending Stories | The News Tale"
        />
        <meta
          name="twitter:description"
          content="Stay informed with the latest breaking news, top headlines, and trending stories in the USA. Get real-time updates on politics, business, technology, and more from The News Tale. Your trusted source for reliable news."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dch7mrf5x/image/upload/v1725432823/BusImage/pps7fisfn1b0ri5fhwhx.png"
        />
        {bigScreenPost.map((post, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(generateStructuredData(post))}
          </script>
        ))}
      </Helmet>

      {/* Google Analytics (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-QJXG9K5B1M"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-QJXG9K5B1M');
            `,
        }}
      ></script>
      {/* Google Tag Manager */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i) {
              w[l] = w[l] || [];
              w[l].push({'gtm.start':
              new Date().getTime(), event: 'gtm.js'});
              var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5MMC4WSC');
          `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5MMC4WSC"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
      <div className={style.main}>
        {error && <div>{error}</div>}
        <Suspense>
          <NavBar />
        </Suspense>
        <Suspense>
        </Suspense>
        <IntlProvider locale="en" messages={messages["en"]}>
          {bigScreenPost.map((post, index) => (
            <Suspense key={index}>
              <HomePageLayout
                title={post?.title}
                description={post?.description}
                postDate={new Date(post?.updatedAt)}
                authorName={post?.authorData?.author}
                imageUrl={post?.imageUrl?.url}
                content={post?.content}
                adImageSrc={post?.adImageSrc}
                customUrl={post?.customUrl}
                categoryName={post?.catId?.categoryName}
                subCategoryName={post?.subCatId?.subCategoryName}
                imageUrlAlt={post?.imageUrlAlt}
                imageUrlTitle={post?.imageUrlTitle}
                loading={loading}
              />
            </Suspense>
          ))}
          <Suspense>
            <SubLayout />
          </Suspense>
          <Suspense>
            <LifestyleSection />
          </Suspense>
          <Suspense>
            <Alsolike />
          </Suspense>
          <Suspense>
            <Footer
              imageUrl={bigScreenPost[0]?.imageUrl?.url}
              imageUrlAlt={bigScreenPost[0]?.imageUrlAlt}
              imageUrlTitle={bigScreenPost[0]?.imageUrlTitle}
              customUrl={bigScreenPost[0]?.customUrl}
              categoryName={bigScreenPost[0]?.catId?.categoryName}
              subCategoryName={bigScreenPost[0]?.subCatId?.subCategoryName}
              loading={loading}
            />
          </Suspense>
        </IntlProvider>
      </div>
    </HelmetProvider>
  );
};

export default Home;
