import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './HamburgerMenu.module.css';
import SectionGrid from './SectionGrid';

const HamburgerMenu = ({ toggleDrawer }) => {
  return (
    <div className={styles.hamburgerMenu}>
      <IconButton
        onClick={toggleDrawer(false)} // This will close the drawer
        className={styles.closeButton}
        aria-label="close menu"
      >
        <CloseIcon />
      </IconButton>
      <SectionGrid />
    </div>
  );
};

export default HamburgerMenu;
