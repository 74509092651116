// state.js
import { atom } from 'recoil';

// Atom to hold the posts data
export const postsState = atom({
  key: 'postsState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

// Atom to manage loading state
export const loadingState = atom({
  key: 'loadingState',
  default: true,
});

// Atom to manage error state
export const errorState = atom({
  key: 'errorState',
  default: null,
});
