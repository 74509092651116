import React from 'react';
import { Card, CardContent, Typography, Skeleton } from '@mui/material';
import styles from './LoadingTemplate.module.css';

const LoadingTemplate = ({
  imageHeight ,
  imageWidth,
  titleHeight ,
  subtitleHeight ,
  titleWidth ,
  subtitleWidth,
  authorText 
}) => {
  return (
    <Card className={styles.loadingCard}>
      {/* Skeleton for Image */}
      <Skeleton 
        variant="rectangular" 
        className={styles.imageSkeleton} 
        sx={{ width: `${imageWidth}px`, height: `${imageHeight}px` }} 
      />

      {/* Content Skeleton */}
      <CardContent>
        {/* Skeleton for Title */}
        <Skeleton 
          variant="text" 
          className={styles.titleSkeleton} 
          sx={{ height: `${titleHeight}px`, width: titleWidth }} 
        />
        
        {/* Skeleton for Subtitle */}
        <Skeleton 
          variant="text" 
          className={styles.subtitleSkeleton} 
          sx={{ height: `${subtitleHeight}px`, width: subtitleWidth }} 
        />
        
        {/* Static text for Author/Publisher */}
        <Typography className={styles.author}>{authorText}</Typography>
      </CardContent>
    </Card>
  );
};

export default LoadingTemplate;
